import React, { useContext, useState } from 'react';
import { FcGoogle } from 'react-icons/fc';
import firebase from 'firebase';
import { auth } from '../../utils/firebase';
import { useHistory } from 'react-router';
import { setAccessToken, setLocalUser, setTokenExpire } from '../../services/localStorage';
import { login } from '../../services/auth';
import AppContext from '../../common/AppContext';
import Modal from 'react-modal';
import '../../assets/styles/common/custom-modal.scss';

Modal.setAppElement('#root');

interface IProps {
  disabled?: boolean;
}

const GoogleLogin = ({ disabled }: IProps): JSX.Element => {
  const provider = new firebase.auth.GoogleAuthProvider();
  const history = useHistory();
  const { appLogin } = useContext(AppContext);
  const [errorModalOpen, setErrorModalOpen] = useState(false);

  const firebasePopupLogin = async () => {
    try {
      const response = await auth.signInWithPopup(provider);
      const user = response.user;
      if (user) {
        const firebase_token = await user.getIdToken();
        if (!firebase_token) {
          throw new Error('Não foi possível fazer login');
        }
        const { email, displayName, photoURL, uid } = user;
        const profilePhoto = photoURL !== undefined ? photoURL : '';

        if (email && displayName && uid) {
          await login({ email, firebase_token });
          setLocalUser({
            email,
            id: uid,
            profile: {
              name: displayName,
              image: profilePhoto,
            },
          });
        }
        appLogin(firebase_token);
        setAccessToken(firebase_token);
        setTokenExpire();
        history.push('/clientes');
      } else {
        throw new Error('Não foi possível fazer login');
      }
    } catch (err) {
      console.error(err);
      setErrorModalOpen(true);
    }
  };

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const email = e.target['email'].value;
    const password = e.target['password'].value;

    try {
      const response = await auth.signInWithEmailAndPassword(email, password);
      const user = response.user;

      if (user) {
        const firebase_token = await user.getIdToken();
        if (!firebase_token) {
          throw new Error('Não foi possível fazer login');
        }
        const { displayName, photoURL, uid } = user;
        const profilePhoto = photoURL !== undefined ? photoURL : '';

        if (displayName && uid) {
          await login({ email, firebase_token });
          setLocalUser({
            email,
            id: uid,
            profile: {
              name: displayName,
              image: profilePhoto,
            },
          });
        }
        appLogin(firebase_token);
        setAccessToken(firebase_token);
        setTokenExpire();
        history.push('/clientes');
      } else {
        throw new Error('Não foi possível fazer login');
      }
    } catch (err) {
      console.error(err);
      setErrorModalOpen(true);
    }
  };

  return (
    <>
      <form onSubmit={handleFormSubmit} className="login-form">
        <div className="form-group">
          <label htmlFor="email">E-mail:</label>
          <input type="email" id="email" name="email" required />
        </div>
        <div className="form-group">
          <label htmlFor="password">Senha:</label>
          <input type="password" id="password" name="password" required />
        </div>
        <button type="submit">Entrar</button>
      </form>

      <Modal
        isOpen={errorModalOpen}
        onRequestClose={() => setErrorModalOpen(false)}
        portalClassName="custom-modal-portal"
        className="custom-modal">
        <h2>ERRO DE PERMISSIONAMENTO</h2>
        <p>Ops! Parece que você não possui a permissão necessária para acessar a plataforma.</p>
        <p>
          Entre em contato com <b>cx@ipnet.cloud</b> em caso de dúvida.
        </p>
        <button onClick={() => setErrorModalOpen(false)}>OK</button>
      </Modal>

      <p>ou</p>
      <button disabled={disabled} type="button" className="btn-google-login" onClick={firebasePopupLogin}>
        <FcGoogle /> ENTRAR COM O GOOGLE
      </button>
    </>
  );
};

export default GoogleLogin;
