import React from 'react';
import GoogleLogin from './GoogleLogin';
import IpnetFooterLogo from '../../assets/images/ipnet_footer_logo.svg';

const Public = (): JSX.Element => {
  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Lógica para lidar com o envio do formulário
  };

  return (
    <section className="page-section login-page">
      <div className="page-content login-card">
        {/* <h4>BEM-VINDO (A) AO</h4> */}
        <h1>Cloud Billing Dashboard</h1>
        <p>Entre e descubra dados relevantes sobre a utilização da sua nuvem!</p>
        <GoogleLogin />
      </div>
      <footer className="page-footer">
        <img
          src={IpnetFooterLogo}
          alt="Ipnet logo, o nome ipnet escrito em letras pretas com o subtitulo 'growth partner'"
        />
      </footer>
    </section>
  );
};

export default Public;
