import React, { Fragment, useContext, useEffect } from 'react';
import { getTokenExpire, setAccessToken, setLocalUser, setTokenExpire } from '../../services/localStorage';
import { auth } from '../../utils/firebase';
import { login } from '../../services/auth';
import AppContext from '../../common/AppContext';

const AuthMonitor = (): JSX.Element => {
  useEffect(() => {
    const timeToCheck = 1170000; //checking each 19 minutes and 30 seconds
    setInterval(checkRefreshToken, timeToCheck);
    checkRefreshToken();
  }, []);

  const { appLogin } = useContext(AppContext);

  const refreshToken = async () => {
    const user = auth.currentUser;
    if (user) {
      const { email, displayName, photoURL, uid } = user;
      const firebase_token = await user.getIdToken(true);
      if (email && displayName && photoURL && uid) {
        await login({ email, firebase_token });
        setAccessToken(firebase_token);
        setLocalUser({
          email,
          id: uid,
          profile: {
            name: displayName,
            image: photoURL,
          },
        });
      }
      appLogin(firebase_token);
      setAccessToken(firebase_token);
      setTokenExpire();
    }
  };

  const checkRefreshToken = () => {
    const now = new Date();
    const expirationTime = getTokenExpire();
    if (now >= expirationTime) {
      refreshToken();
    }
  };
  return <Fragment />;
};

export default AuthMonitor;
