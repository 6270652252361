import IUser from '../interfaces/IUser';

export interface ICustomer {
  customer_id: string;
  customer_name: string;
}

export const getLocalUser = (): IUser => {
  const user: IUser = {
    id: '',
    email: '',
    profile: {
      name: '',
      image: '',
    },
  };

  const localUserData = localStorage.getItem('userData');

  if (localUserData) {
    const userData = JSON.parse(localUserData);
    user.id = userData.id || '';
    user.email = userData.email || '';
    user.profile.name = userData.profile.name || '';
    user.profile.image = userData.profile.image || '';
  }

  return user;
};

export const setLocalUser = (userData: IUser): IUser => {
  const localUserData = JSON.stringify(userData);
  localStorage.setItem('userData', localUserData);

  return userData;
};

export const setTokenExpire = (): void => {
  const expirationDate = new Date();
  expirationDate.setMinutes(expirationDate.getMinutes() + 58);
  localStorage.setItem('token_expires', expirationDate.getTime().toString());
};

export const getTokenExpire = (): Date => {
  const token_expires = localStorage.getItem('token_expires');
  if (token_expires) {
    const token = parseInt(token_expires);
    const date = new Date(token);
    if (date.toString() !== 'Invalid Date') {
      return date;
    }
  }
  return new Date();
};

export const userLogout = (): boolean => {
  localStorage.clear();

  return true;
};

export const getAccessToken = (): string => {
  const accessToken = localStorage.getItem('accessToken');

  if (accessToken) {
    return accessToken;
  }

  return '';
};

export const setAccessToken = (accessToken: string): void => {
  localStorage.setItem('accessToken', accessToken);
};

export const getFirebaseToken = (): string => {
  const firebaseToken = localStorage.getItem('firebaseToken');

  if (firebaseToken) {
    return firebaseToken;
  }

  return '';
};

export const setFirebaseToken = (accessToken: string): void => {
  localStorage.setItem('firebaseToken', accessToken);
};

export const setCustomer = (customers: string[]): void => {
  localStorage.setItem('customers', JSON.stringify(customers));
};

export const getCustomer = (): string[] => {
  const customersString = localStorage.getItem('customers');

  if (customersString) {
    return JSON.parse(customersString);
  }

  return [];
};
